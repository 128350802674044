import { useState, FormEvent } from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import LoadingButton from "@obr/common/LoadingButton";

import { useNotificationsStore } from "@obr/features/notifications/store";
import { useAuthStore } from "./store";
import { isPlainObject } from "@obr/common/isPlainObject";
import { hasProperty } from "@obr/common/helpers/hasProperty";
import { SupabaseError } from "@obr/features/supabase/SupabaseError";

type SignInOptions = {
  hasAccount: boolean;
  passwordSignIn: boolean;
};

function isSignInOptionsType(
  signInOptions: unknown
): signInOptions is SignInOptions {
  return (
    signInOptions !== null &&
    signInOptions !== undefined &&
    hasProperty(signInOptions, "hasAccount") &&
    hasProperty(signInOptions, "passwordSignIn")
  );
}

type EmailFormProps = {
  onSignIn: () => void;
  prefillEmail?: string;
};

const formContainerSx = { mt: 1, width: "100%" };

function EmailForm({ onSignIn, prefillEmail }: EmailFormProps) {
  const showNotification = useNotificationsStore(
    (state) => state.showNotification
  );

  const verifyOTP = useAuthStore((state) => state.verifyOTP);
  const signInWithPassword = useAuthStore((state) => state.signInWithPassword);
  const createPassword = useAuthStore((state) => state.createPassword);
  const setShouldRedirect = useAuthStore((state) => state.setShouldRedirect);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(prefillEmail ?? "");
  const [password, setPassword] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [signInOptions, setSignInOptions] = useState<SignInOptions | null>(
    null
  );
  const [verified, setVerified] = useState(false);

  async function handleSignInUp(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(
        `${
          import.meta.env.VITE_OWLBEAR_RODEO_DOMAIN
        }/api/v2/auth/get-sign-in-options`,
        {
          method: "POST",
          body: JSON.stringify({
            email: email.toLowerCase(),
          }),
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_SUPABASE_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        let message = "Request error";

        if (response.status === 429) {
          message = "Too many sign in requests"
        }
        
        try {
          const responseBody = await response.json();

          if (
            isPlainObject(responseBody) &&
            hasProperty(responseBody, "error") &&
            responseBody.error instanceof SupabaseError
          ) {
            message = responseBody.error.message;
          }
        } catch (e) {
          throw Error(message);
        } finally {
          throw Error(message);
        }
      }

      const result = await response.json();

      if (isPlainObject(result) && hasProperty(result, "data")) {
        const { data } = result;

        if (!isSignInOptionsType(data)) {
          throw Error(
            "Invalid response: unable to process the sign in request"
          );
        }

        setSignInOptions(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    }
  }

  async function handleVerification(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setLoading(true);
      // Prevent auth status from redirecting after otp verification
      setShouldRedirect(false);
      const result = await verifyOTP(verificationCode, email.toLowerCase());

      if (!result) {
        // TODO: close otp form on error
        throw Error("Unable to verify token");
      }
      setLoading(false);
      setVerified(true);
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    }
  }

  async function handleSignUp(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setLoading(true);
      // Prevent auth status from redirecting after otp verification
      setShouldRedirect(false);
      const { success, data } = await verifyOTP(
        verificationCode,
        email.toLowerCase()
      );

      if (!success) {
        throw Error("Unable to verify token");
      }

      if (!data) {
        throw Error("Unable to create session");
      }

      const response = await fetch(
        `${
          import.meta.env.VITE_OWLBEAR_RODEO_DOMAIN
        }/api/v2/stripe/user/profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.session?.access_token}`,
          },
        }
      );

      if (!response.ok) {
        throw Error("Unable to create password: failed to update profile");
      }

      setLoading(false);
      setVerified(true);
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    }
  }

  async function handlePasswordCreate(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setLoading(true);

      await createPassword(password);

      setLoading(false);
      onSignIn();
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    }
  }

  async function handlePassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setLoading(true);
      await signInWithPassword({ email: email.toLowerCase(), password });

      setLoading(false);
      setShouldRedirect(true);
      onSignIn();
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    }
  }

  const emailField = (
    <TextField
      margin="normal"
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      // https://github.com/whatwg/html/issues/4445#issuecomment-476588308
      // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
      autoComplete="username"
      autoFocus
      value={email}
      type="email"
      onChange={(e) => {
        if (signInOptions) {
          setSignInOptions(null);
        }
        setEmail(e.target.value);
      }}
      size="small"
      disabled={prefillEmail ? true : loading}
    />
  );

  const emailForm = (
    <Stack component="form" onSubmit={handleSignInUp} sx={formContainerSx}>
      {emailField}
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        loading={loading}
      >
        Continue with Email
      </LoadingButton>
    </Stack>
  );

  const signUpVerify = (
    <Stack component="form" onSubmit={handleSignUp} sx={formContainerSx}>
      {emailField}
      <Typography variant="caption" textAlign="center">
        We just sent you a temporary sign up code. <br />
        Please check your inbox.
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        name="Sign up code"
        label="Sign up code"
        id="otp"
        autoComplete="off"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value.trim())}
        size="small"
        disabled={loading}
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        loading={loading}
      >
        Create new account
      </LoadingButton>
    </Stack>
  );

  const signInVerify = (
    <Stack component="form" onSubmit={handleVerification} sx={formContainerSx}>
      {emailField}
      <Typography variant="caption" textAlign="center">
        We just sent you a temporary sign in code. <br />
        Please check your inbox.
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        name="Sign in code"
        label="Sign in code"
        id="sign-in-code"
        autoComplete="off"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value.trim())}
        size="small"
        disabled={loading}
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        loading={loading}
      >
        Continue with sign in code
      </LoadingButton>
    </Stack>
  );

  const passwordForm = (
    <Stack component="form" onSubmit={handlePassword} sx={formContainerSx}>
      {emailField}
      <TextField
        margin="normal"
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        size="small"
        disabled={loading}
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        loading={loading}
      >
        Continue with Password
      </LoadingButton>
    </Stack>
  );

  const passwordCreateForm = (
    <Stack
      component="form"
      onSubmit={handlePasswordCreate}
      sx={formContainerSx}
    >
      <TextField
        margin="normal"
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        size="small"
        disabled={loading}
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        loading={loading}
      >
        Create A Password
      </LoadingButton>
    </Stack>
  );

  if (verified) {
    return passwordCreateForm;
  } else if (!signInOptions) {
    return emailForm;
  } else {
    if (signInOptions.hasAccount && signInOptions.passwordSignIn) {
      return passwordForm;
    } else if (signInOptions.hasAccount) {
      return signInVerify;
    } else {
      return signUpVerify;
    }
  }
}

export default EmailForm;
